<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>{{ $t('About Us') }}</h2>
                    </div>
                </div>
			</div>

			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.png" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.png" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.png" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.png" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.png" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.png" alt="shape"></div>
        </div>
		<!-- End Page Title -->

        <!-- Start About Area -->
		<section class="about-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-md-12">
						<div class="about-image">
							<img src="../../../assets/img/1.png" alt="image">
						</div>
					</div>

					<div class="col-lg-6 col-md-12">
						<div class="about-content">
							<div class="section-title">
								<div class="bar"></div>
								<p style="text-align: justify">{{ $t('Startblock is a company dedicated to helping entrepreneurs and companies around the world make their ideas come true hand in hand with technology.') }}</p>
							</div>
							<p style="text-align: justify;">{{ $t('We have worked on projects across five continents, in several business areas such as telecommunication, agriculture, real estate and more. Adapting to your needs will not be a problem. Our mission will always be that your project is successful from its initial stages of gestation.') }}</p> 
							<p style="text-align: justify;">{{ $t('We can help you close the technology gap that always exists between a good idea and its implementation.') }}</p>
						</div>
					</div>
				</div>

				<br>
				<div class="about-inner-area">
					<div class="row">
						<div class="col-lg-4 col-md-6 col-sm-6">
							<div class="about-text">
								<h3>{{ $t('Our Story') }}</h3>
								<p style="text-align: justify;" >{{ $t('We started in 2018, bringing together a group of professionals with a great passion for technology. Since then we have given our clients the tools to turn their ideas into sustainable businesses with the help of blockchain technology.') }}</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 col-sm-6">
							<div class="about-text">
								<h3>{{ $t('Our Mission') }}</h3>
								<p style="text-align: justify;">{{ $t('Help entrepreneurs and companies transform their ideas and products into digital solutions that can leverage blockchain technology to their advantage. Our passion will always be to take our clients to the next level of the digital age.') }}</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 col-sm-6">
							<div class="about-text">
								<h3>{{ $t('About Us') }}</h3>
								<p style="text-align: justify;" >{{ $t("We are people that laugh and work hard. We can do anything because we are an interdisciplinary group with skills in software engineering and passion for innovation. We'll use every tool in our box to make your project succeed.") }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End About Area -->

        <Team></Team>

        <!-- Start Ready To Talk Area -->
		<section class="ready-to-talk">
			<div class="container">
				<h3>{{ $t('Ready to start your project?') }}</h3>
				<p>{{ $t('We are ready to answer your questions') }}</p>
				<a href="#" class="btn btn-primary">{{ $t('Contact Us') }}</a>
			</div>
		</section>
		<!-- End Ready To Talk Area -->
    <Partner></Partner>
    <!--  <Funfacts></Funfacts> -->
    </div>
</template>

<script>
import Team from '../../it-startup/Team';
import Partner from '../../it-startup/Partner';
// import Funfacts from '../common/Funfacts';
export default {
    name: 'AboutStyleOne',
    components: {
        Team,
        Partner,
        // Funfacts
    }
}
</script>
